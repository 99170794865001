<template>
  <div class="wrapper">
    <MainCrochet/>
  </div>
</template>

<script>
import MainCrochet from './components/MainCrochet.vue'

export default {
  name: 'App',
  components: {
    MainCrochet
  }
}
</script>

<style>
  :root {
    --base-color: #383838;
    --module-color: #4a4a4a;   
    --shadow-color: #1e1e1e;
    --button-color: gray;
    --button-border: #939393;
    --text-color: white;
    --text-color-red: #a52929;
    --transparent: transparent;
    --text-placeholder-color: #757575;
  }

  body {
    margin: 0px;
    padding: 0px;
    background-color: var(--base-color);
  }

  #app {
    font-family:  'Quicksand', Helvetica, Arial, sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100vw;
    height: 100vh;
  }

  .wrapper {
    width: 100%;
    height: 100%;
    background-color: var(--base-color);
    display: grid;
    align-items: center;
    justify-items: center;
    overflow-x: hidden;
    overflow-y: auto;
    gap: 10px 0px;
  }
</style>
