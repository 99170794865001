<template>
  <transition name="enterScreen">
    <div v-if="showLogin" class="loginContainer">
      <input type="number" class="standardInput" v-model="inputValue" :class="{bounce : bounceInputLogin}" @click="input" @input="checkValue" placeholder="enter your code..."/>
      <input type="button" class="loginButton" :class="{bounce : bounceLoginButton, colorRed : loginNotPossible}" @click="executeLogin" value="login"/>
    </div>
  </transition>
  <div class="mainCrochet">
    <div class="showHideLogin" v-if="!login"><div class="imgUser" :class="{bounce : bounceLoginShowHide}" @click="showHideLogin"></div></div>
    <div class="counter"  :class="(!login) || (!login && showLogin) ? 'small' : 'big'">{{count}}</div>
    <div class="functions">
      <div class="plus" :class="{bounce : bouncePlus}" @click="plus">+</div>
      <div class="minus" :class="{bounce : bounceMinus, colorRed : minusNotPossible}" @click="minus">-</div>
      <div class="reset" :class="{bounce : bounceReset, colorRed : resetNotPossible}" v-if="!confirmReset" @click="resetConfirm">reset</div>
      <div class="reset colorRed" :class="{bounce : bounceReset}" v-else @click="executeReset">execute reset?</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'MainCrochet',
  data() {
    return {
      inputValue: "",
      count: 0,
      bouncePlus: false,
      bounceMinus: false,
      bounceReset: false,
      bounceInputLogin: false,
      bounceLoginButton: false,
      bounceLoginShowHide: false,
      confirmReset: false,
      resetNotPossible: false,
      minusNotPossible: false,
      loginNotPossible: false,
      showLogin: false,
      login: false
    }
  },
  methods: {
    input(){
      this.checkResetConfirm();
      this.executeBounce("input");
    },
    plus(){
      this.checkResetConfirm();
      this.executeBounce("plus");
      this.count = this.count+1;
      this.saveCount();
    },
    minus(){
      this.checkResetConfirm();
      this.executeBounce("minus");
      if(this.count <= 0){
        this.minusNotPossible = true;
        setTimeout(() =>{
          this.minusNotPossible = false;
        }, 100);
        this.count = 0;
      }
      else {
        this.count = this.count-1;
      }
      this.saveCount();
    },
    resetConfirm(){
      this.executeBounce("reset");
      if(this.count >= 1){
        this.confirmReset = true;
      }
      else{
        this.resetNotPossible = true;
        setTimeout(() =>{
          this.resetNotPossible = false;
        }, 100);
      }
    },
    saveCount(){
      if(this.login === true){
        axios.post(`${process.env.VUE_APP_API_PREFIX}/setCount?auth=${this.inputValue}`, null, {
          params: {
            count: this.count
          }
        }).then((response) => {
          console.log(response);
        }).catch((error) => {
          console.log(error);
        });
      }
    },
    executeReset(){
      this.executeBounce("reset");
      this.count = 0;
      this.confirmReset = false;
      this.saveCount();
    },
    checkResetConfirm(){
      if(this.confirmReset === true){
        this.confirmReset = false;
      }
    },
    checkValue(){
      if(this.inputValue.toString().length >= 5){
        this.inputValue = this.inputValue.toString().slice(0, -1);
      }
    },
    executeLogin(){
      this.checkResetConfirm();
      this.executeBounce("loginButton");

      if(this.inputValue != ""){
        axios.get(`${process.env.VUE_APP_API_PREFIX}/getCount?auth=${this.inputValue}`).then((response) => {
          this.count = parseInt(response.data);
          this.login = true;
          this.showHideLogin();
        }).catch((error) => {
          this.login = false;
          this.loginNotPossible = true;
          setTimeout(() =>{
            this.loginNotPossible = false;
          }, 100);
        });
      }
      else{
        this.loginNotPossible = true;
        setTimeout(() =>{
          this.loginNotPossible = false;
        }, 100);
      }
    },
    showHideLogin(){
      this.checkResetConfirm();
      this.executeBounce("loginShowHide");
      if(this.showLogin == true){
        this.showLogin = false;
      }
      else{
        this.showLogin = true;
      }
    },
    executeBounce(type){
      switch(type){
        case "input":
          this.bounceInputLogin = true;
          setTimeout(() =>{
            this.bounceInputLogin = false;
          }, 100);
          break;
        case "loginShowHide":
          this.bounceLoginShowHide = true;
          setTimeout(() =>{
            this.bounceLoginShowHide = false;
          }, 100);
          break;
        case "loginButton":
          this.bounceLoginButton = true;
          setTimeout(() =>{
            this.bounceLoginButton = false;
          }, 100);
          break;
        case "plus":
          this.bouncePlus = true;
          setTimeout(() =>{
            this.bouncePlus = false;
          }, 100);
          break;
        case "minus":
          this.bounceMinus = true;
          setTimeout(() =>{
            this.bounceMinus = false;
          }, 100);
          break;
        case "reset":
          this.bounceReset = true;
          setTimeout(() =>{
            this.bounceReset = false;
          }, 100);
          break;
        default:
          console.log("The animation could not be executed.");
          break;
      }
    }
  }
}
</script>

<style scoped>
  .imgUser {
    flex-basis: 5vh;
    height: 5vh;
    min-width: 31px;
    min-height: 31px;
    background-image: url('../assets/user.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: 0.1s;
  }

  .imgUser:hover {
    transform: scale(1.038);
  }

  .showHideLogin {
    width: 100%;
    height: 10%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    padding: 0px 1vw 0px 0px;
  }

  .loginContainer {
    width: 50%;
    height: 80%;
    min-height: 150px;
    border-radius: 10px;
    background-color: var(--module-color);
    box-shadow: 0px 0px 10px var(--shadow-color);
    display: grid;
    justify-content: center;
    align-items: center;
    justify-items: center;
    transition: 0.3s;
  }


  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    font-family:  'Quicksand', Helvetica, Arial, sans-serif !important;
    -moz-appearance: textfield;
  }

  .loginButton{
    font-family:  'Quicksand', Helvetica, Arial, sans-serif !important;
    width: 100%;
    height: 4rem;
    border-radius: 10px;
    background-color: gray;
    border: 1px solid var(--button-border);
    color: var(--text-color);
    transition: 0.1s;
    font-size: 2rem;
    user-select: none;
  }

  .loginButton:hover {
    transform: scale(1.038);
  }

  .standardInput {
    width: 100%;
    height: 3rem;
    font-size: 2rem;
    background-color: var(--transparent);
    color: var(--text-color);
    border: none;
    transition: 0.2s linear;
    border-bottom: 1px solid var(--text-placeholder-color);
  }

  .standardInput:hover {
    cursor: pointer;
    transform: scale(1.038);
  }

  .standardInput:focus {
    border-bottom: 1px solid var(--text-color);
    outline: none;
  }

  .mainCrochet {
    width: 50%;
    height: 80%;
    min-height: 460px;
    border-radius: 10px;
    background-color: var(--module-color);
    box-shadow: 0px 0px 10px var(--shadow-color);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
  }

  .counter {
    flex-basis: 100%;
    border-radius: 10px 10px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    font-size: 14rem;
    color: var(--text-color);
    word-break: break-all;
    text-align: center;
    overflow: hidden;
  }

  .big {
    height: 70%;
  }

  .small {
    height: 60%;
  }

  .functions {
    flex-basis: 90%;
    height: 30%;
    border-radius: 0px 0px 40px 40px;
    color: var(--text-color);
    display: grid;
    grid-template-columns: 45% 45%;
    gap: 10%;
    font-size: 2rem;
    user-select: none;
  }

  .plus {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 10px;
    background-color: gray;
    border: 1px solid var(--button-border);
    transition: 0.2s;
  }

  .minus {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 10px;
    background-color: gray;
    border: 1px solid var(--button-border);
    transition: 0.2s;
  }

  .reset {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    grid-column: 1 / span 2;
    width: 100%;
    height: 4rem;
    border-radius: 10px;
    background-color: gray;
    border: 1px solid var(--button-border);
    transition: 0.1s;
  }

  .colorRed {
    color: var(--text-color-red);
  }

  .plus:hover, .minus:hover, .reset:hover {
    transform: scale(1.038);
  }

  .bounce {
    animation: bounce 0.1s;
  }

  @keyframes bounce {
    1% {
      transform: scale(1.038);
    }

    50% {
      transform: scale(1.06);
    }

    100% {
      transform: scale(1.038);
    }
  }

  .enterScreen-enter-active,
  .enterScreen-leave-active {
    transition: 0.3s;
    opacity: 100;
    transform: translateY(0%);
  }

  .enterScreen-enter-from,
  .enterScreen-leave-to {
    transform: translateY(-100%);
    opacity: 0;
  }

  @media only screen and (max-width: 800px) {
    .loginContainer {
      width: 100%;
      height: 100%;
      border-radius: unset;
      background-color: transparent;
      box-shadow: none;
      border-bottom: 1px solid var(--module-color);
    }

    .mainCrochet {
        width: 100%;
        height: 100%;
        border-radius: unset;
        background-color: transparent;
        box-shadow: none;
      }

    .standardInput {
      width: 80%;
    }

    .loginButton {
      width: 80%;
    }

    .showHideLogin {
      padding: 0px 4vw 0px 0px;
    }
  }
</style>
